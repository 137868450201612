@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 255, 255, 255;
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb))
}

body::-webkit-scrollbar {
  display: none;
}

.mx-2-p p {
    margin-top: 8px;
    margin-bottom: 8px;
}